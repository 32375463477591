import consumer from "./consumer"
$(document).on('turbolinks:load', function () {
  var id = $(".allianceid").attr("id");
  if (typeof id !== 'undefined' && id !== false) {
    consumer.subscriptions.create({channel: "RoomChannel", id: id}, {
      connected() {
        // Called when the subscription is ready for use on the server
        //console.log("Connected to the room!");
        //$('#msg').append('<div class="message"> You have entered the channel.</div>')
      },

      disconnected(data) {
        // Called when the subscription has been terminated by the server
        //console.log("Disconnected to the room!");
      },

      received(data) {
        var currentdate = new Date(); 
        if (data.action == "join") {
          $('#msg').append('<div class="message">' + data.user + ' has joined the channel.</div>')
        }
        if (data.action == "message") {
          var langis = $("#locallang").attr("class");
          if (langis == "it") {
            var message = data.content_it
          }
          if (langis == "en") {
            var message = data.content_en
          }
          $('#msg').append('<div class="message mid'+data.mid+'">'+ data.user +'   '+data.time+'<br />' + message + '</div>')
        }

        var elem = document.getElementById('msg');
        elem.scrollTop = elem.scrollHeight;
        // Called when there's incoming data on the websocket for this channel
      }
    });
    };
});

let submit_messages;
$(document).on('turbolinks:load', function () {
  submit_messages()
})
submit_messages = function () {
  $('#message_content').on('keydown', function (event) {
    if (event.keyCode == 13) {
      $('input').click()
      event.target.value = ''
      event.preventDefault()
    }
  })
}
