import consumer from "./consumer"
$(document).on('turbolinks:load', function () {
  var userid = $(".memberid").attr("id");
  if (typeof userid !== 'undefined' && userid !== false) {
    consumer.subscriptions.create({channel: "UserChannel", id: userid}, {
      connected() {
        if (typeof userid !== 'undefined' && userid !== false) {
          $('.textdetails').append('<p>You are connected as user.</p>');
        }
        // Called when the subscription is ready for use on the server
      },
      disconnected() {
      },

      received(data) {

        if ($(".memberid").attr("id") == data.content) {
          audio.play();
          $(".textdetails").html('<p style="color:green;">' + data.nick +' Send!</p>');
        }

        if (typeof data.duration !== 'undefined') {
          var seconds = parseInt(data.duration.split(".")[0]);
          var milliseconds = parseInt((data.duration.split(".")[1])*100);
          var remainingTime = (seconds * 1000) + milliseconds;
          var startTime = null;

          requestAnimationFrame(updateCountdown);
          function updateCountdown(timestamp) {
            if (!startTime) startTime = timestamp;
            var elapsed = timestamp - startTime;
            var remainingSeconds = Math.floor((remainingTime - elapsed) / 1000);
            var remainingMilliseconds = remainingTime - (remainingSeconds * 1000) - elapsed;
            document.getElementById('seconds').textContent = padNumber(remainingSeconds);
            document.getElementById('milliseconds').textContent = padNumber(remainingMilliseconds, 3);
            if (remainingSeconds == 2) {
              $(".timercontainer").css("background-color", "#F28C28");
            }
            if (remainingSeconds == 1) {
              $(".timercontainer").css("background-color", "#E4D00A");
            }
            if (remainingSeconds == 0) {
              $(".timercontainer").css("background-color", "#50C878");
            }
            if (elapsed < remainingTime) {
              requestAnimationFrame(updateCountdown);
            } else {
              $(".timercontainer").css("background-color", "#313338");
              document.getElementById('seconds').textContent = "00";
              document.getElementById('milliseconds').textContent = "000";
            }
          }
          function padNumber(number, length) {
            length = length || 2;
            var paddedNumber = String(number);
            while (paddedNumber.length < length) {
              paddedNumber = '0' + paddedNumber;
            }
            return paddedNumber.substring(0, 4);;
          }
        }

      }
    });
  }
});