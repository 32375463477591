import consumer from "./consumer"
$(document).on('turbolinks:load', function () {
  var id = $(".dallianceid").attr("id");
  if (typeof id !== 'undefined' && id !== false) {
    consumer.subscriptions.create({channel: "DoomChannel", id: id}, {
      connected() {
        // Called when the subscription is ready for use on the server
        //console.log("Connected to the room!");
        //$('#msg').append('<div class="message"> You have entered the channel.</div>')
      },

      disconnected(data) {
        // Called when the subscription has been terminated by the server
        //console.log("Disconnected to the room!");
      },

      received(data) {
        if (data.action == "message") {
          alert(data.message);
        }
        if (data.action == "castle_placement") {
          $("#"+data.x+"x"+data.y).html(data.nick);
        }

        if (data.action == "attack_line") {
          attack_line($('#'+data.x1+'x'+data.y1), $('#'+data.x2+'x'+data.y2), data.uniqid, data.duration, data.position, data.belongs_to, data.player, data.starttime);
        }

        if (data.action == "speed_line") {
          initspeed(data.id, data.timeleft, data.start);
        }

        if (data.action == "building_capture") {
          initcapture(data.x, data.y, data.owner, data.holder, data.bubble);
        }

        if (data.action == "disband_building") {
          initdisband(data.x, data.y);
        }
      }
    });
    };
});