import consumer from "./consumer"
$(document).on('turbolinks:load', function () {
  var id = $(".manageallianceid").attr("id");
  if (typeof id !== 'undefined' && id !== false) {
    consumer.subscriptions.create({channel: "ManageChannel", id: id}, {
      connected() {
        // Called when the subscription is ready for use on the server
        //console.log("Connected to the room!");
        //$('#msg').append('<div class="message"> You have entered the channel.</div>')
      },

      disconnected(data) {
        // Called when the subscription has been terminated by the server
        //console.log("Disconnected to the room!");
      },

      received(data) {
        if (data.action == "remove") {
          $("#l"+data.id).remove();
        }
        if (data.action == "next") {
          $("#nextup").html("Next up is "+data.nick)
        }
        if (data.action == "list") {
          $("#owned").html("");
          $.each(JSON.parse(data.listitems), function(key, list) {
            $("#owned").append('<div id="owned'+key.replace(/\s/g, '')+'" class="col-sm-3"></div>');
            $("#owned"+key.replace(/\s/g, '')).append('<p><b>'+key+'</b></p>');
            $.each(list, function(i, names) {
              $("#owned"+key.replace(/\s/g, '')).append('<p>'+names+'</p>');
            });
          });
        }
      }
    });
    };
});